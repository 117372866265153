import React, { useState } from "react"
import styled from "styled-components"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import SEO from "../components/SEO"
import { PageSegment } from "../components"
import { GridOneColumn } from "../styles"

const FilterBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75px;
  background-color: var(--grey);
  margin-bottom: 20px;

  button {
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    width: 150px;
    height: 75px;

    &:hover {
      outline: none;
      background-color: var(--lightGrey);
    }
  }
  .active {
    background-color: var(--darkGrey);
  }
`

const CreativesGrid = styled.div`
  margin: 20px 0px;
  display: grid;
  grid-template-columns: 1fr;

  justify-items: center;
  grid-gap: 20px 20px;

  .pushDown {
    position: static;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 110px;

    .pushDown {
      position: relative;
      top: 130px;
    }
  }
`

const ImageContainer = styled(Link)`
  text-decoration: none;
  color: var(--black);
  width: 250px;
  height: 375px;
  overflow: hidden;

  .gatsby-image-wrapper {
  }

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    width: 80%;
    height: 6em;
    margin: 0 auto;
    position: relative;
    top: -150px;
    text-align: center;
  }

  @media (min-width: 375px) {
    width: 280px;
    height: 420px;
  }

  @media (min-width: 768px) {
    width: 300px;
    height: 450px;
  }
`

type Creatives = {
  creatives: {
    nodes: {
      name: string
      slug: string
      image: any
      _id: string
      email: string
      site: string
      facebook: string
      instagram: string
      description: string
      creativesPageBanner: any
      profileBanner: any
      imagesGallery: any[]
      productType: any[]
    }[]
  }
}

export const Creatives = () => {
  const [filters, setFilters] = useState<string[]>([])

  const query = useStaticQuery(graphql`
    query MyQuery {
      creatives: allSanityCreative {
        nodes {
          _id
          name
          slug {
            current
          }
          productType {
            name
            id
          }
          creativesPageBanner {
            asset {
              fluid(maxHeight: 450, maxWidth: 300) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      productType: allSanityProductType {
        nodes {
          _id
          name
        }
      }
    }
  `)

  return (
    <>
      <SEO title="Creatives" />
      <FilterBar>
        {query.productType.nodes.map((node: any) => {
          return (
            <button
              key={node._id}
              className={filters.includes(node.name) ? "active" : ""}
              onClick={() => {
                let newFilter = [...filters]
                if (filters.includes(node.name)) {
                  newFilter = newFilter.filter(filter => filter !== node.name)
                } else {
                  newFilter.push(node.name)
                }
                setFilters(newFilter)
              }}
            >
              {node.name}
            </button>
          )
        })}
      </FilterBar>
      <PageSegment>
        <GridOneColumn>
          <CreativesGrid>
            {query.creatives.nodes.map((node: any, i: number) => {
              let pushDonw = false
              if (i === 1 || (i - 1) % 3 == 0) {
                pushDonw = true
              }

              let filter = false

              if (filters.length !== 0) {
                filter = true

                node.productType.forEach((productType: any) => {
                  if (filters.includes(productType.name)) filter = false
                })
              }

              if (filter) return null
              return (
                <ImageContainer
                  key={node._id}
                  to={`/creative/${node.slug.current}`}
                  replace
                >
                  <Img
                    fluid={node.creativesPageBanner.asset.fluid}
                    alt={node.name}
                  />
                  <div className="box">
                    <h2>{node.name}</h2>
                  </div>
                </ImageContainer>
              )
            })}
          </CreativesGrid>
        </GridOneColumn>
      </PageSegment>
    </>
  )
}

export default Creatives
